<template>
  <div class="blog-layout">
    <section class="hero hero-post">
      <div class="container"></div>
    </section>

    <section class="index-section s-post">
      <div class="container">
        <div class="layout-media display--grid">
          <div class="layout-main s-post--entry">
            <div class="post-back btn-action display--flex">
              <router-link to="/media-center" class="btn btn-o btn-sm"
                >Вернуться в Медиа-центр</router-link
              >
            </div>
            <div v-if="singleBlog">
              <div :id="`blogPage${singleBlog.id}`">
                <h1 class="post-title">{{ singleBlog.title }}</h1>
                <!-- <div class="post-share display--flex">Поделиться</div> -->
                <div class="post-thumbnail">
                  <img
                    :src="`${backUrl}${singleBlog.image.path}`"
                    :alt="singleBlog.title"
                  />
                </div>
                <div class="post-intro" v-html="singleBlog.short_text"></div>
                <div class="post-content" v-html="singleBlog.text"></div>
                <div class="post-date">{{ singleBlog.created_at }}</div>
              </div>
            </div>
          </div>
          <aside class="layout-aside">
            <story-list-block progress></story-list-block>
          </aside>
        </div>
      </div>
    </section>

    <subscribe-block />

    <div v-for="(blog, index) in nextBlogs" :key="blog.id">
      <section class="index-section s-post">
        <div class="container">
          <div class="layout-media display--grid">
            <div v-if="blog" class="layout-main s-post--entry">
              <div :id="`blogPage${blog.id}`">
                <h1 class="post-title">{{ blog.title }}</h1>
                <!-- <div class="post-share display--flex">Поделиться</div> -->
                <div class="post-thumbnail">
                  <img :src="`${backUrl}${blog.image.path}`" alt="" />
                </div>
                <div class="post-intro" v-html="blog.short_text"></div>
                <div class="post-content" v-html="blog.text"></div>
                <div class="post-date">{{ blog.created_at }}</div>
              </div>
            </div>
            <aside class="layout-aside">
              <story-list-block progress></story-list-block>
            </aside>
          </div>
        </div>
      </section>

      <component :is="typeComponent[index]" />
    </div>
  </div>
</template>

<script>
import {
  computed,
  onBeforeMount,
  onBeforeUnmount,
  ref,
  watch,
} from "@vue/runtime-core";
import { useStore } from "vuex";
import StoryListBlock from "../../components/story-list-block/StoryListBlock.vue";
import { useRoute } from "vue-router";
import SubscribeBlock from "../../components/banners/subscribe-block/SubscribeBlock.vue";
import RateTheLevel from "../../components/banners/rate-the-level/RateTheLevel.vue";
import ProductSlider from "../../components/sliders/product-slider/ProductSlider.vue";
import { useHead } from '@vueuse/head';
export default {
  components: { StoryListBlock, SubscribeBlock, RateTheLevel, ProductSlider },
  name: "blog-page",
  inject: ["backUrl"],
  setup() {
    const store = useStore(),
      route = useRoute();

    const allClientHeight = ref(275);

    const nextBlogs = ref([]),
      currentId = ref(route.params.id),
      currentBlogProgress = ref([]),
      inProgress = ref(false),
      typeComponent = ref([]),
      state = ref(0);

    const singleBlog = computed(() => store.state.blog.singleBlog),
      blogProgress = computed(() => store.state.blog.blogProgress),
      currentPage = computed(() =>
        document.querySelector(`#blogPage${currentId.value}`)
      ),
      nextBlog = computed(() => store.state.blog.nextBlog);

    useHead({
      title: computed(() => (singleBlog.value ? singleBlog.value.title : "")),
      meta: [
        {
          property: "og:description",
          content: computed(() =>
            singleBlog.value ? singleBlog.value.short_text : ""
          ),
        },
      ],
    });

    let checkValue = () => {
      // let clientHeight = currentPage.value ? currentPage.value.clientHeight : 0;
      let bottomOfWindow =
        document.documentElement.scrollTop + window.innerHeight >=
        document.documentElement.offsetHeight - 1620;
      let halfPage =
        document.documentElement.scrollTop >= allClientHeight.value;

      if (halfPage && !inProgress.value) {
        changeProgress(50, currentId.value, false);
      }

      if (bottomOfWindow && !inProgress.value) {
        changeProgress(100, currentId.value, true);

        if (
          (singleBlog.value && singleBlog.value.next_article) ||
          (nextBlog.value && nextBlog.value.next_article)
        ) {
          inProgress.value = true;
          switch (state.value) {
            case 0:
              typeComponent.value.push("product-slider");
              allClientHeight.value += 610;
              break;
            case 1:
              typeComponent.value.push("rate-the-level");
              allClientHeight.value += 374;
              break;
            case 2:
              typeComponent.value.push("subscribe-block");
              allClientHeight.value += 275;
              break;
            default:
              typeComponent.value.push("product-slider");
              break;
          }
          state.value < 2 ? state.value++ : (state.value = 0);
          let request = nextBlog.value
            ? nextBlog.value.next_article
            : singleBlog.value.next_article;
          allClientHeight.value += (currentPage.value.clientHeight * 2) / 3;
          store
            .dispatch("blog/getNextBlog", request)
            .then(() => {
              nextBlogs.value.push(nextBlog.value);
              currentId.value = nextBlog.value.id;
              changeProgress(1, nextBlog.value.id, false);
              inProgress.value = false;
            })
            .then(() => {
              allClientHeight.value += currentPage.value.clientHeight / 3;
            });
        }
      }
    };
    let changeProgress = (value, id, done) => {
      let indexProg;
      let pageProgres = {
        value,
        id,
        done,
      };
      let progress = blogProgress.value.find((ch, index) => {
        if (ch.id == id) {
          indexProg = index;
          return true;
        }
      });
      currentBlogProgress.value = blogProgress.value;
      if (!progress) {
        currentBlogProgress.value.push(pageProgres);
      } else if (value > progress.value) {
        currentBlogProgress.value[indexProg] = pageProgres;
      }
      store.commit("blog/setBlogProgressStorage", currentBlogProgress);
    };

    onBeforeMount(() => {
      store.commit("blog/setNextBlog", null);
      store.dispatch("blog/getSingleBlog", route.params.id).then(() => {
        currentId.value = singleBlog.value.id;
        changeProgress(1, singleBlog.value.id, false);
        window.addEventListener("scroll", checkValue);
        allClientHeight.value += currentPage.value.clientHeight / 3;
      });
    });

    onBeforeUnmount(() => {
      window.removeEventListener("scroll", checkValue);
    });

    watch(
      () => route.params.id,
      () => {
        if (route.path.startsWith("/register/stati")) {
          nextBlogs.value = [];
          typeComponent.value = [];

          store.commit("blog/setNextBlog", null);
          store.commit("blog/setSingleBlog", null);
          store
            .dispatch("blog/getSingleBlog", route.params.id)
            .then(() => {
              currentId.value = route.params.id;
              changeProgress(1, route.params.id, false);
            })
            .then(() => {
              allClientHeight.value = 275 + currentPage.value.clientHeight / 3;
            });
          window.removeEventListener("scroll", checkValue);
          window.scrollTo({ top: 0, behavior: "smooth" });
          window.addEventListener("scroll", checkValue);
        }
      }
    );

    return {
      singleBlog,
      nextBlogs,
      blogProgress,
      currentId,
      currentPage,
      currentBlogProgress,
      changeProgress,
      nextBlog,
      allClientHeight,
      inProgress,
      typeComponent,
      state,
    };
  },
};
</script>

<style scoped>
.blog-layout {
  padding-bottom: 80px;
}

.index-section + .s-media:not(.bg-light),
.index-section + .s-banner,
.s-banner + .index-section {
  padding-top: 0;
}

section:not(.hero) + .s-banner,
section:not(.hero) + .s-products--slider {
  padding-bottom: 0;
}

.s-post--entry .post-back {
  margin-bottom: 22px;
}

.s-post--entry .post-share {
  align-items: center;
  justify-content: flex-start;
  margin: 0 0 20px 0;
}

.s-post--entry .post-title {
  margin: 0 0 15px 0;
  font-size: 46px;
  font-weight: 300;
  line-height: 51px;
  letter-spacing: -0.02em;
  text-align: left;
}

/* .s-post--entry .post-intro {
  margin-bottom: 20px;
  font-size: 28px;
  line-height: 39px;
  text-align: left;
} */

.post-title {
  color: var(--color);
  text-decoration: none;
}

.post-content,
.post-intro {
  font-size: 12pt;
  line-height: 28px;
  letter-spacing: 0;
  text-align: left;
  word-break: break-word;
}

.post-content p,
.post-content ul,
.post-content ol {
  margin: 0 0 10px;
}

.post-content p + ul,
.post-content p + ol,
.post-content ul + ul,
.post-content ul + ol,
.post-content ol + ul,
.post-content ol + ol {
  margin-bottom: 20px;
}

.post-content ul {
  padding: 0;
  list-style: none;
}

.post-content ul > li {
  position: relative;
  margin-bottom: 12px;
  padding-left: 30px;
}

.post-content ul > li::before {
  position: absolute;
  display: block;
  content: "";
  top: 14px;
  left: 0;
  width: 20px;
  height: 1px;
  background: var(--accent);
}

.post-content ol > li {
  position: relative;
  margin-bottom: 12px;
}

.s-post--entry .post-date {
  margin-top: 20px;
  color: var(--color-light-2);
  font-size: 16px;
  line-height: 19px;
}

.post-thumbnail {
  display: block;
}

.post-thumbnail img {
  display: block;
  width: 100%;
  object-fit: cover;
}

a.post-thumbnail:hover {
  opacity: 0.7;
}

.post-lg .post-thumbnail,
.s-post--entry .post-thumbnail {
  margin: 0 0 20px 0;
}

.post-lg .post-thumbnail img,
.s-post--entry .post-thumbnail img {
  height: 380px;
  border-radius: 10px;
}

@media (max-width: 767px) {
  .s-post--entry .post-title {
    font-size: 36px;
    line-height: 43px;
  }

  .s-post--entry .post-intro {
    font-size: 24px;
    line-height: 29px;
  }

  .post-content {
    font-size: 18px;
    line-height: 22px;
  }
}
</style>